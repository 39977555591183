@import 'theme/variable' ;
$primary: #10266b;
$secondary: #464545;
$dark: #220069;
$light:#1F357D;
@import 'theme/style';
@import 'theme/responsive';
@import 'theme/customize';
@import 'theme/nav';
@import 'theme/theme-dark';
@import 'theme/theme-rtl';